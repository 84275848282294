import { useMemo } from 'react'
import merge from 'deepmerge'
import isEqual from 'lodash/fp/isEqual'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { APOLLO_STATE_PROP_NAME } from './config'

let apolloClient

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: false,
    link: new HttpLink({
      uri: '/api/graphql',
    }),
    cache: new InMemoryCache(),
  })
}

function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()
  if (initialState) {
    const existingCache = _apolloClient.extract()
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })
    _apolloClient.cache.restore(data)
  }
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export default function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  return useMemo(() => initializeApollo(state), [state])
}
