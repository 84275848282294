import { ApolloLink } from '@apollo/client'
import pick from 'lodash/fp/pick'

const authLink = (kissHeaders, reqHeaders) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const session = kissHeaders._kisskissbankbank_session_new

      let finalHeaders = {
        ...headers,
        ...pickRequestHeaders(reqHeaders),
        'X-CSRF-Token': kissHeaders.authenticity_token,
      }

      if (session !== 'undefined') {
        finalHeaders['Cookie'] = `_kisskissbankbank_session_new=${session};`
      }

      console.log('[AUTH LINK] header', finalHeaders)

      return { headers: finalHeaders }
    })

    return forward(operation)
  })

const pickRequestHeaders = pick([
  'x-vercel-forwarded-for',
  'x-forwarded-for',
  'user-agent',
  'x-vercel-id',
  'origin',
])

export default authLink
