import pick from 'lodash/fp/pick'
import SetCookie from 'set-cookie-parser'

export const parseCookies = (response) => {
  const setCookieHeader = response.headers.raw()['set-cookie']
  const remoteCookies = SetCookie.parse(setCookieHeader, { map: true })

  return remoteCookies
}

export const setCsrfCookie = (cookies, remoteCookies) => {
  const authenticityToken = remoteCookies.authenticity_token

  if (!authenticityToken) return

  cookies.set(
    authenticityToken.name,
    authenticityToken.value,
    getCookieOptions(authenticityToken),
  )
}

export const setSessionCookie = (cookies, remoteCookies) => {
  const kisskissbankbankSession = remoteCookies._kisskissbankbank_session_new

  if (!kisskissbankbankSession) return

  cookies.set(
    kisskissbankbankSession.name,
    kisskissbankbankSession.value,
    {
      ...getCookieOptions(kisskissbankbankSession),
      domain: process.env.ROOT_DOMAIN,
    },
  )
}

export const getCookieOptions = pick(['path', 'secure', 'httpOnly'])
