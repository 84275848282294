import Head from 'next/head'
import '../styles/global.scss'
import { ApolloProvider } from '@apollo/client'
import { appWithTranslation } from 'next-i18next'
import { useApollo } from '../apollo'
import {
  HeaderNav,
  KissKissBankBankLogo,
  Container,
} from '@kisskissbankbank/kitten'
import React from 'react'

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps)
  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta
          name="Description"
          content="KissKissBankBank starter for NextJS projects"
        />
        <meta name="robots" content="noindex, nofollow" />
        <link
          rel="stylesheet"
          href="https://d3v4jsc54141g1.cloudfront.net/maax/maax_v3.css"
          media="screen"
          type="text/css"
        />
      </Head>
      <HeaderNav size="small">
        <HeaderNav.Logo href="#">
          <KissKissBankBankLogo />
        </HeaderNav.Logo>
      </HeaderNav>
      <Container>
        <Component {...pageProps} />
      </Container>
    </ApolloProvider>
  )
}

export default appWithTranslation(MyApp)
